import { lockAbi } from "../Contract/lock";
import { pairAbi } from "../Contract/pair";
import { ethers } from "ethers";
import { erc20ABI } from "wagmi";

export const fetchLockCount = async (provider, lockAddress, isLp) => {
  try {
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    let count = 0;

    if (isLp) {
      count = await contract.allLpTokenLockedCount();
    } else {
      count = await contract.allNormalTokenLockedCount();
    }

    return count.toString();
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const fetchTokenLocks = async (provider, lockAddress, start, end) => {
  try {
    let lockData = [];
    let convertLock = [];
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    if (end === "0") {
      return lockData;
    } else {
      lockData = await contract.getCumulativeNormalTokenLockInfo(start, end);
    }
    for (let i in lockData) {
      convertLock.push(dataConvert(lockData[i]));
    }
    convertLock = await fetchTokenInfo(convertLock, provider);

    return convertLock.slice().reverse();
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchLpLocks = async (provider, lockAddress, start, end) => {
  try {
    let lockData = [];
    let convertLock = [];
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    if (end == 0) {
      return lockData;
    } else {
      lockData = await contract.getCumulativeLpTokenLockInfo(start, end);
    }
    for (let i in lockData) {
      convertLock.push(dataConvert(lockData[i]));
    }

    convertLock = await fetchLPTokenInfo(convertLock, provider);
    return convertLock.slice().reverse();
  } catch (error) {
    console.log(error);
    return [];
  }
};

const dataConvert = (lock) => {
  let json = {
    amount: lock["amount"].toString(),
    factory: lock["factory"],
    tokenAddress: lock["token"],
  };
  return json;
};

export const fetchTokenInfo = async (lockData, provider) => {
  try {
    let copyLockData = [...lockData];
    let tokenData = [];
    for (let i in copyLockData) {
      const contract = new ethers.Contract(
        copyLockData[i].tokenAddress,
        erc20ABI,
        provider
      );
      let name = contract.name();
      let symbol = contract.symbol();
      let decimals = contract.decimals();
      tokenData.push(name, symbol, decimals);
    }
    const promise = await Promise.all(tokenData);
    for (let i in copyLockData) {
      copyLockData[i].name = promise[i * 3];
      copyLockData[i].symbol = promise[i * 3 + 1];
      copyLockData[i].decimals = promise[i * 3 + 2];
    }
    return copyLockData;
  } catch (error) {
    console.log(error);
  }
};

export const fetchLPTokenInfo = async (lockData, provider) => {
  try {
    let copyLockData = [...lockData];
    let tokenData = [];
    let pairData = [];
    for (let i in copyLockData) {
      const pairContract = new ethers.Contract(
        copyLockData[i].tokenAddress,
        pairAbi,
        provider
      );
      const token0 = pairContract.token0();
      const token1 = pairContract.token1();
      pairData.push(token0, token1);
    }
    pairData = await Promise.all(pairData);
    for (let i in copyLockData) {
      const contract0 = new ethers.Contract(
        pairData[i * 2],
        erc20ABI,
        provider
      );
      const contract1 = new ethers.Contract(
        pairData[i * 2 + 1],
        erc20ABI,
        provider
      );
      let name0 = contract0.name();
      let name1 = contract1.name();
      let symbol0 = contract0.symbol();
      let symbol1 = contract1.symbol();
      tokenData.push(name0, name1, symbol0, symbol1);
    }
    const promise = await Promise.all(tokenData);
    for (let i in copyLockData) {
      copyLockData[i].name = promise[i * 4] + "/" + promise[i * 4 + 1];
      copyLockData[i].symbol = promise[i * 4 + 2] + "/" + promise[i * 4 + 3];
      copyLockData[i].decimals = 18;
    }
    return copyLockData;
  } catch (error) {
    console.log(error);
  }
};
