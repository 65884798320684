import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./Pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import NotFound from "./Components/NotFound";
import CreateLock from "./Pages/CreateLock";
import TokenLockInfo from "./Pages/TokenLock/Components/tokenLockInfo";
import TokenLockInfoView from "./Pages/TokenLock/Components/tokenLockInfoView";
import TokenLock from "./Pages/TokenLock";
import LiquitidyLock from "./Pages/LiquidityLock";
import LiquidityLockInfo from "./Pages/LiquidityLock/Components/liquidityLockInfo";
import LiquidityLockInfoView from "./Pages/LiquidityLock/Components/liquidityLockInfoView";

// CSS
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  let cId = 3797;
  return (
    <div className="appWrapper">
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <div className="appContent">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/create-lock" element={<CreateLock cId={cId} />} />

            <Route path="/token-lock" element={<TokenLock cId={cId} />} />
            <Route path="/token-lock/:address" element={<TokenLockInfo cId={cId} />} />
            <Route
              path="/token-lock/:address/view/:id"
              element={<TokenLockInfoView cId={cId}/>}
            />

            <Route path="/liquidity-lock" element={<LiquitidyLock cId={cId} />} />
            <Route
              path="/liquidity-lock/:address"
              element={<LiquidityLockInfo cId={cId} />}
            />
            <Route
              path="/liquidity-lock/:address/view/:id"
              element={<LiquidityLockInfoView cId={cId} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
