import React, { useState, useEffect } from "react";
import "../../TokenLock/index.css";
import Modal from "react-bootstrap/Modal";
import { DatePicker } from "antd";
import moment from "moment";
import { FaPencilAlt } from "react-icons/fa";
import { rpcUrl } from "../../../multichain/rpcUrl";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useLocation } from "react-router-dom";
import { fetchLockRecord } from "../../../hook/fetchLockRecord";
import { link } from "../../../multichain/explorerLinks";
import Countdown from "react-countdown";
import { lockAddress } from "../../../multichain/contractAddresses";
import EditLockTitle from "../../../hook/editLockTitle";
import TransferLockOwnership from "../../../hook/transferLockOwnership";
import EditExtendLock from "../../../hook/editExtendLock";
import EditUnlock from "../../../hook/editUnlock";
function LiquidityLockInfoView({ cId }) {
  const [trigger, setTrigger] = useState(0);

  const location = useLocation();
  const { address } = useAccount();
  const id = location.pathname.split("/")[4];
  const tokenAddress = location.pathname.split("/")[2];
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const chainRpcUrl = chain?.rpcUrls?.default;
  const contractAddress = lockAddress[chainId ? chainId : cId];
  const provider = new ethers.providers.StaticJsonRpcProvider(
     rpcUrl[cId] 
  );
  const fetchRecord = async () => {
    try {
      const data = await fetchLockRecord(
        provider,
        contractAddress,
        tokenAddress,
        true,
        id
      );
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState();
  useEffect(() => {
    setLoading(true);
    fetchRecord().then((res) => {
      setRecord(res);
      setLoading(false);
    });
  }, [chainRpcUrl, address]);
  useEffect(() => {
    let interval = setInterval(() => {
      fetchRecord().then((res) => {
        setRecord(res);
      });
    }, 5_000);
    return () => clearInterval(interval);
  }, [chainRpcUrl, address]);
  const [modalTransfer, setModalTransfer] = useState(false);
  const showTransferModal = () => {
    setModalTransfer(true);
  };
  const hideTransferModal = () => {
    setModalTransfer(false);
  };

  const [modalExtend, setModalExtend] = useState(false);
  const showExtendModal = () => {
    setModalExtend(true);
  };
  const hideExtendModal = () => {
    setModalExtend(false);
  };

  const [modalTitle, setModalTitle] = useState(false);
  const showTitleModal = () => {
    setModalTitle(true);
  };
  const hideTitleModal = () => {
    setModalTitle(false);
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="lockViewTimeCount">
          <span>00</span>
          <span>00</span>
          <span>00</span>
          <span>00</span>
        </div>
      );
    } else {
      return (
        <div className="lockViewTimeCount">
          <span>{days}</span>
          <span>{hours}</span>
          <span>{minutes}</span>
          <span>{seconds}</span>
        </div>
      );
    }
  };
  const handleGoLinks = (address) => {
    window.open(link[chainId ? chainId : cId] + address, "_blank");
  };
  return (
    <div className="tokenLockInfo">
      <div className="lockInfo">
        <div className="lockViewTimer">
          <span>Unlocks in</span>
          {loading ? (
            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap:"4px"
              }}
            >
              Loading <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : record?.lockRecord ? (
            record?.lockRecord?.unlockDate && (
              <Countdown
                date={record?.lockRecord?.unlockDate * 1000}
                renderer={renderer}
              />
            )
          ) : (
            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No Data
            </div>
          )}
        </div>
      </div>
      <div className="lockInfo">
        <div className="lockInfoTitle">Token Info</div>
        {loading ? (
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap:"4px"
            }}
          >
            Loading <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : record?.lockRecord ? (
          <div className="lockInfoContent">
            <div className="lockInfoContentItem">
              <div className="lockInfoContentItemTitle">Token Address</div>
              <a
                href="#"
                target="_blank"
                className="lockInfoContentLink"
                onClick={() => handleGoLinks(tokenAddress)}
              >
                {tokenAddress}
              </a>
            </div>
            <div className="lockInfoContentItem">
              <div className="lockInfoContentItemTitle">Token Name</div>
              <div className="lockInfoContentItemText">
                {record?.tokenData?.name}
              </div>
            </div>
            <div className="lockInfoContentItem">
              <div className="lockInfoContentItemTitle">Token Symbol</div>
              <div className="lockInfoContentItemText">
                {record?.tokenData?.symbol}
              </div>
            </div>
            <div className="lockInfoContentItem">
              <div className="lockInfoContentItemTitle">Token Decimals</div>
              <div className="lockInfoContentItemText">
                {record?.tokenData?.decimals}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            No Data
          </div>
        )}
      </div>
      <div className="lockInfo">
        <div className="lockInfoTitle">Lock Info</div>
        {loading ? (
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap:"4px"
            }}
          >
            Loading <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : record?.lockRecord ? (
          <>
            <div className="lockInfoContent">
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">
                  Token Amount Locked
                </div>
                <div className="lockInfoContentItemText">
                  {(
                    record?.lockRecord?.amount /
                    10 ** record?.tokenData?.decimals
                  ).toFixed(3) + " "}
                  {record?.tokenData?.symbol}
                </div>
              </div>
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Lock Title</div>
                <div
                  className="lockInfoContentItemText"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "4px",
                  }}
                >
                  {record?.lockRecord?.description}
                  {record?.lockRecord?.owner === address && (
                    <FaPencilAlt
                      onClick={() => showTitleModal()}
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Owner</div>
                <a
                  href="#"
                  target="_blank"
                  className="lockInfoContentLink"
                  onClick={() => handleGoLinks(record?.lockRecord?.owner)}
                >
                  {record?.lockRecord?.owner}
                </a>
              </div>
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Lock Date</div>
                <div className="lockInfoContentItemText">
                  {moment(record?.lockRecord?.lockDate * 1000).format(
                    "YYYY.MM.DD hh:mm"
                  )}{" "}
                  (Local)
                </div>
              </div>
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Unlock Date</div>
                <div className="lockInfoContentItemText">
                  {moment(record?.lockRecord?.unlockDate * 1000).format(
                    "YYYY.MM.DD hh:mm"
                  )}{" "}
                  (Local)
                </div>
              </div>
            </div>
            {record?.lockRecord?.owner === address && (
              <div className="lockInfoButtonArea">
                <button
                  className="lockInfoButton"
                  onClick={() => showTransferModal()}
                >
                  Transfer Lock Ownership
                </button>
                {/* <button className="lockInfoButton">Renounce Lock Ownership</button> */}

                <EditUnlock
                  record={record}
                  provider={provider}
                  contractAddress={contractAddress}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  buttonName="Renounce Lock Ownership"
                />
                <button
                  className="lockInfoButton"
                  onClick={() => showExtendModal()}
                >
                  Extend Lock
                </button>
                <EditUnlock
                  record={record}
                  provider={provider}
                  contractAddress={contractAddress}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  buttonName="Unlock"
                />
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            No Data
          </div>
        )}
      </div>

      <Modal
        show={modalTransfer}
        onHide={hideTransferModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Ownership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransferLockOwnership
            record={record}
            provider={provider}
            contractAddress={contractAddress}
            setTrigger={setTrigger}
            trigger={trigger}
            setModalTransfer={setModalTransfer}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalExtend}
        onHide={hideExtendModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Extend Lock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditExtendLock
            record={record}
            provider={provider}
            contractAddress={contractAddress}
            setTrigger={setTrigger}
            trigger={trigger}
            setModalExtend={setModalExtend}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalTitle}
        onHide={hideTitleModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Lock Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditLockTitle
            record={record}
            provider={provider}
            contractAddress={contractAddress}
            setTrigger={setTrigger}
            trigger={trigger}
            setModalTitle={setModalTitle}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LiquidityLockInfoView;
