import React, { useEffect, useState } from "react";
import "../Pages/TokenLock/index.css";
import { BigNumber, ethers } from "ethers";
import { useSigner } from "wagmi";
import { lockAbi } from "../Contract/lock";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function EditUnlock({
  record,
  provider,
  contractAddress,
  setTrigger,
  trigger,
  buttonName,
}) {
  const navigate = useNavigate();
  const { data: signer } = useSigner();
  const currentSigner =
    signer === undefined || signer === null ? provider : signer;

  const contract = new ethers.Contract(contractAddress, lockAbi, currentSigner);
  const lockId = record?.lockRecord?.id;

  const [withdrawableToken, setWithdrawableToken] = useState(0);
  const getWithdrawableTokens = async () => {
    try {
      const withdrawableTokens = await contract.withdrawableTokens(lockId);
      setWithdrawableToken(
        Number(BigNumber.from(withdrawableTokens).toString())
      );
    } catch (error) {
      console.log(error);
      setWithdrawableToken(0);
    }
  };

  useEffect(() => {
    getWithdrawableTokens();
  }, [trigger]);

  const unlockButtonValidation = () => {
    if (buttonName !== "Unlock") {
      return false;
    }
    if (
      record?.lockRecord?.cycle !== "0" &&
      withdrawableToken !== 0
    ) {
      return false;
    } else if (
      Number(record?.lockRecord?.unlockDate) < Math.floor(Date.now() / 1000)
    ) {


      return false;
    } else {
            return true;
    }
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = async () => {
    const saveToast = toast.loading("Transaction in progress...", {
      position: "top-center",
    });
    setSaveLoading(true);
    try {
      let tx;
      {
        buttonName === "Unlock" ?
        tx = await contract.unlock(lockId):
        tx = await contract.renounceLockOwnership(lockId)

      }
      await tx.wait();
      setTrigger(trigger + 1);
      toast.update(saveToast, {
        render: "Transaction Successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      if(buttonName === "Unlock"){
        navigate("/")
      }
    } catch (error) {
      console.log(error);
      toast.update(saveToast, {
        render: error
          ? error.reason !== undefined
            ? error.reason
            : error.message !== undefined
            ? error.message
            : "Something went wrong"
          : "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    }
    setSaveLoading(false);
  };

  return (
    <button
      className="lockInfoButton"
      disabled={saveLoading || unlockButtonValidation()}
      style={{
        cursor:
          saveLoading || unlockButtonValidation() ? "not-allowed" : "pointer",
        opacity: saveLoading || unlockButtonValidation() ? 0.5 : 1,
        gap: saveLoading ? "6px" : "0",
      }}
      onClick={() => handleSave()}
    >
      {buttonName}
      {saveLoading && <i className="fas fa-spinner fa-spin"></i>}
    </button>
  );
}

export default EditUnlock;
