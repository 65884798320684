import { lockAbi } from "../Contract/lock";
import { pairAbi } from "../Contract/pair";
import { ethers } from "ethers";
import { erc20ABI } from "wagmi";

export const fetchLockRecord = async (
  provider,
  lockAddress,
  tokenAddress,
  isLp,
  id
) => {
  try {
    let data = [];
    let tokenData = [];
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    if (isLp) {
      tokenData = await fetchLPTokenData(provider, tokenAddress);
    } else {
      tokenData = await fetchTokenData(provider, tokenAddress);
    }
    const count = await contract.totalLockCountForToken(tokenAddress);
    const allLock = await contract.getLocksForToken(
      tokenAddress,
      0,
      count.toString()
    );
    let lockRecord = [];
    for (let i in allLock) {
      lockRecord.push(dataConvert(allLock[i], tokenData));
    }
    if (id) {
      lockRecord = lockRecord.filter((item) => item.id === id)[0];
    }
    return {
      tokenData: {
        name: tokenData[0],
        symbol: tokenData[1],
        decimals: tokenData[2],
      },
      lockRecord,
    };
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTokenData = async (provider, tokenAddress) => {
  try {
    const contract = new ethers.Contract(tokenAddress, erc20ABI, provider);
    const name = contract.name();
    const symbol = contract.symbol();
    const decimals = contract.decimals();
    return await Promise.all([name, symbol, decimals]);
  } catch (error) {
    console.log(error);
  }
};

export const fetchLPTokenData = async (provider, tokenAddress) => {
  try {
    const contract = new ethers.Contract(tokenAddress, pairAbi, provider);

    const token0 = contract.token0();
    const token1 = contract.token1();
    const tokenContract = await Promise.all([token0, token1]);
    const contract0 = new ethers.Contract(tokenContract[0], erc20ABI, provider);
    const contract1 = new ethers.Contract(tokenContract[1], erc20ABI, provider);
    const name0 = contract0.name();
    const name1 = contract1.name();
    const symbol0 = contract0.symbol();
    const symbol1 = contract1.symbol();
    const decimals = 18;
    const data = await Promise.all([name0, name1, symbol0, symbol1, decimals]);
    return [data[0] + "/" + data[1], data[2] + "/" + data[3], decimals];
  } catch (error) {
    console.log(error);
  }
};

export const dataConvert = (lockData, tokenData) => {
  try {
    let json = {
      id: lockData["id"].toString(),
      amount: lockData["amount"].toString(),
      lockDate: lockData["lockDate"].toString(),
      owner: lockData["owner"],
      tokenAddress: lockData["token"],
      tokenName: tokenData[0],
      tokenSymbol: tokenData[1],
      tokenDecimals: tokenData[2],
      unlockDate: lockData["tgeDate"].toString(),
      cycle: lockData["cycle"].toString(),
      cycleBps: lockData["cycleBps"].toString(),
      description: lockData["description"],
      tgeBps: lockData["tgeBps"].toString(),
      unlockedAmount: lockData["unlockedAmount"].toString(),
    };
    return json;
  } catch (error) {
    console.log(error);
  }
};

// export const fetchLPLockRecord = async( provider, lockAddress, tokenAddress ) => {
//     try {
//         let tokenData = []
//         const contract = new ethers.Contract(lockAddress, lockAbi, provider);
//         tokenData = await fetchTokenData(provider, tokenAddress);
//         const count = await contract.totalLockCountForToken(tokenAddress);
//         const allLock = await contract.getLocksForToken(tokenAddress, 0, count.toString());
//         let lockRecord = [];
//         for (let i in allLock) {
//             lockRecord.push(dataConvert(allLock[i], tokenData));
//         }
//         return lockRecord;
//     } catch (error) {
//         return []
//         console.log(error);
//     }
// }
