import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../TokenLock/index.css";
import { FaSearch } from "react-icons/fa";
import { lockAddress } from "../../multichain/contractAddresses";
import { rpcUrl } from "../../multichain/rpcUrl";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { fetchLpLocks, fetchLockCount } from "../../hook/fetchAllLock";
import { fetchMyLpLock } from "../../hook/fetchMyLock";
function LiquidityLock({ cId }) {
  const [activeFilter, setActiveFilter] = useState("all");
  const [myLocks, setMyLocks] = useState();
  const [loadMoreData, setLoadMoreData] = useState(10);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const chainRpcUrl = chain?.rpcUrls?.default;
  const contractAddress = lockAddress[chainId ? chainId : cId];
  const provider = new ethers.providers.StaticJsonRpcProvider(
   rpcUrl[cId] 
  );
  const [lockCount, setLockCount] = useState();
  const [lockData, setLockData] = useState();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState();
  const fethcLockCount = async () => {
    try {
      const data = await fetchLockCount(provider, contractAddress, true);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchLock = async () => {
    try {
      const data = await fetchLpLocks(provider, contractAddress, start, end);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const fethcMyLock = async () => {
    try {
      const data = await fetchMyLpLock(provider, contractAddress, address);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fethcLockCount().then((res) => {
      setLockCount(res);
      if (res > 0) {
        if (res >= 9) {
          setStart(res - 9);
        } else {
          setStart(0);
        }
        setEnd(res);
      }
    });
  }, [chainRpcUrl]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setLoading(true);
    fetchLock().then((res) => {
      setLoading(false);
      if (lockData?.length > 0) {
        for (let i in res) {
          setLockData((oldArray) => [...oldArray, res[i]]);
        }
      } else {
        setLockData(res);
      }
    });
  }, [chainRpcUrl, end]);
  const [myLockLoading, setMyLoading] = useState(false);
  useEffect(() => {
    setMyLoading(true);
    fethcMyLock().then((res) => {
      setMyLocks(res);
      setMyLoading(false);
    });
  }, [address, chainRpcUrl]);

  const loadMore = () => {
    if (start - 9 >= 0) {
      setStart(start - 9);
      setEnd(end - 9);
    } else {
      setStart(0);
      setEnd(start - 1);
    }
  };
  return (
    <div className="lock">
      {/* Title */}
      <div className="lockTitle">
        <span>Locked Liquidity Tokens List</span>
      </div>

      {/* Search */}
      <div className="lockSearch">
        <FaSearch className="lockSearchIcon" />
        <input
          type="text"
          placeholder="Search by token address..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>

      {/* Filter */}
      <div className="lockFilter">
        <button
          onClick={() => setActiveFilter("all")}
          className={
            activeFilter === "all"
              ? "lockFilterButtonActive"
              : "lockFilterButton"
          }
        >
          All
        </button>
        <button
          onClick={() => setActiveFilter("my")}
          className={
            activeFilter === "my"
              ? "lockFilterButtonActive"
              : "lockFilterButton"
          }
        >
          My Lock
        </button>
      </div>

      {/* Table */}
      <div className="lockTable">
        {activeFilter === "my" ? (
          <>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "45%",
                    }}
                  >
                    Token
                  </th>
                  <th
                    style={{
                      width: "45%",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    style={{
                      width: "10%",
                    }}
                  ></th>
                </tr>
              </thead>
              {myLocks &&
                myLocks?.length > 0 &&
                myLocks?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div>{item.name}</div>
                      <div style={{ fontWeight: "400" }}>{item.symbol}</div>
                    </td>
                    <td>{(item.amount / 10 ** item.decimals).toFixed(2)}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <NavLink
                        to={
                          "/liquidity-lock/" +
                          item.tokenAddress +
                          "/view/" +
                          item.id
                        }
                        className="lockView"
                      >
                        View
                      </NavLink>
                    </td>
                  </tr>
                ))}
            </table>
            {myLockLoading && (
              <div className="lockTableEmpty">
                <span>
                  Loading <i className="fas fa-spinner fa-spin"></i>
                </span>
              </div>
            )}
            {myLocks?.length < 1 && (
              <div className="lockTableEmpty">
                <span>No Data</span>
              </div>
            )}
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "40%",
                    }}
                  >
                    Token
                  </th>
                  <th
                    style={{
                      width: "50%",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    style={{
                      width: "10%",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {lockData &&
                  lockData?.length > 0 &&
                  lockData?.map((item, index) => {
                    if (item.tokenAddress.includes(search)) {
                      return (
                        <tr key={index}>
                          <td>
                            <div>{item.name}</div>
                            <div style={{ fontWeight: "400" }}>
                              {item.symbol}
                            </div>
                          </td>
                          <td>
                            {(item.amount / 10 ** item.decimals).toFixed(2)}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <NavLink
                              to={"/liquidity-lock/" + item.tokenAddress}
                              className="lockView"
                            >
                              View
                            </NavLink>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
            {loading && (
              <div className="lockTableEmpty">
                <span>
                  Loading <i className="fas fa-spinner fa-spin"></i>
                </span>
              </div>
            )}
            { lockCount < 1 && (
              <div className="lockTableEmpty">
                <span>No Data</span>
              </div>
            )}
          </>
        )}
      </div>

      {/* Load More */}

      {activeFilter === "all" &&
        lockData?.length > 0 &&
        end > 9 &&
        lockData?.filter((item) => item.tokenAddress.includes(search))
          ?.length >= 9 && (
          <div className="lockLoadMore">
            <button onClick={() => loadMore()}>Load More</button>
          </div>
        )}
    </div>
  );
}

export default LiquidityLock;
