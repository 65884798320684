import { ethers } from "ethers";
import { lockAbi } from "../Contract/lock";
import { erc20ABI } from "wagmi";
import { pairAbi } from "../Contract/pair";
export const fethcMyLock = async (provider, lockAddress, address) => {
  try {
    let lockData = [];
    let myLock = [];
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    if (address) {
      lockData = await contract.normalLocksForUser(address);
      for (let i in lockData) {
        myLock.push(convertData(lockData[i]));
      }
      const data = await fetchTokenInfo(myLock, provider);
      return data.slice().reverse();
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchMyLpLock = async (provider, lockAddress, address) => {
  try {
    let lockData = [];
    let myLock = [];
    const contract = new ethers.Contract(lockAddress, lockAbi, provider);
    if (address) {
      lockData = await contract.lpLocksForUser(address);
      for (let i in lockData) {
        myLock.push(convertData(lockData[i]));
      }
      const data = await fetchLPTokenInfo(myLock, provider);
      return data.slice().reverse();
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchLPTokenInfo = async (lockData, provider) => {
  try {
    let copyLockData = [...lockData];
    let tokenData = [];
    let pairData = [];
    for (let i in copyLockData) {
      const pairContract = new ethers.Contract(
        copyLockData[i].tokenAddress,
        pairAbi,
        provider
      );
      const token0 = pairContract.token0();
      const token1 = pairContract.token1();
      pairData.push(token0, token1);
    }
    pairData = await Promise.all(pairData);
    for (let i in copyLockData) {
      const contract0 = new ethers.Contract(
        pairData[i * 2],
        erc20ABI,
        provider
      );
      const contract1 = new ethers.Contract(
        pairData[i * 2 + 1],
        erc20ABI,
        provider
      );
      let name0 = contract0.name();
      let name1 = contract1.name();
      let symbol0 = contract0.symbol();
      let symbol1 = contract1.symbol();
      tokenData.push(name0, name1, symbol0, symbol1);
    }
    const promise = await Promise.all(tokenData);
    for (let i in copyLockData) {
      copyLockData[i].name = promise[i * 4] + "/" + promise[i * 4 + 1];
      copyLockData[i].symbol = promise[i * 4 + 2] + "/" + promise[i * 4 + 3];
      copyLockData[i].decimals = 18;
    }
    return copyLockData;
  } catch (error) {
    console.log(error);
  }
};
const convertData = (lock) => {
  let data = {
    amount: lock["amount"].toString(),
    cylce: lock["cycle"].toString(),
    cycleBps: lock["cycleBps"].toString(),
    description: lock["description"],
    id: lock["id"].toString(),
    lockDate: lock["lockDate"].toString(),
    owner: lock["owner"],
    tgeBps: lock["tgeBps"].toString(),
    unLockDate: lock["tgeDate"].toString(),
    tokenAddress: lock["token"],
    unlockedAmount: lock["unlockedAmount"].toString(),
  };
  return data;
};

export const fetchTokenInfo = async (lockData, provider) => {
  try {
    let copyLockData = [...lockData];
    let tokenData = [];
    for (let i in copyLockData) {
      const contract = new ethers.Contract(
        copyLockData[i].tokenAddress,
        erc20ABI,
        provider
      );
      let name = contract.name();
      let symbol = contract.symbol();
      let decimals = contract.decimals();
      tokenData.push(name, symbol, decimals);
    }
    const promise = await Promise.all(tokenData);
    for (let i in copyLockData) {
      copyLockData[i].name = promise[i * 3];
      copyLockData[i].symbol = promise[i * 3 + 1];
      copyLockData[i].decimals = promise[i * 3 + 2];
    }
    return copyLockData;
  } catch (error) {
    console.log(error);
  }
};
