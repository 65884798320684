import React, { useState } from "react";
import "../Pages/TokenLock/index.css";
import { ethers } from "ethers";
import { useSigner } from "wagmi";
import { lockAbi } from "../Contract/lock";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import moment from "moment";

function EditExtendLock({
  record,
  provider,
  contractAddress,
  setTrigger,
  trigger,
  setModalExtend,
}) {
  const { data: signer } = useSigner();
  const currentSigner =
    signer === undefined || signer === null ? provider : signer;

  const [lockTime, setLockTime] = useState("");

  const onChangeTime = (date, dateString) => {
    let seconds = new Date(dateString).getTime() / 1000;
    setLockTime(seconds);
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = async () => {
    const contract = new ethers.Contract(
      contractAddress,
      lockAbi,
      currentSigner
    );

    const saveToast = toast.loading("Transaction in progress...", {
      position: "top-center",
    });
    setSaveLoading(true);
    const lockId = record?.lockRecord?.id;
    const lockAmount = ethers.BigNumber.from(record?.lockRecord?.amount);
    try {
      const tx = await contract.editLock(lockId, lockAmount, lockTime);
      await tx.wait();
      setTrigger(trigger + 1);
      setModalExtend(false);
      toast.update(saveToast, {
        render: "Transaction Successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    } catch (error) {
      console.log(error);
      toast.update(saveToast, {
        render: error
          ? error.reason !== undefined
            ? error.reason
            : error.message !== undefined
            ? error.message
            : "Something went wrong"
          : "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    }
    setSaveLoading(false);
  };

  return (
    <div className="transferModal">
      <span>New Date</span>
      <DatePicker
        showTime
        format="YYYY-MM-DD HH:mm"
        placeholder="Select Date"
        disabledDate={(current) => {
          return (
            current &&
            current <
              moment(Number(record?.lockRecord?.unlockDate) * 1000).endOf("day")
          );
        }}
        className="extendDate"
        onChange={onChangeTime}
      />
      <button
        disabled={saveLoading || lockTime === ""}
        style={{
          cursor: saveLoading || lockTime === "" ? "not-allowed" : "pointer",
          opacity: saveLoading || lockTime === "" ? 0.5 : 1,
          gap: saveLoading ? "6px" : "0",
        }}
        onClick={() => handleSave()}
      >
        Save{saveLoading && <i className="fas fa-spinner fa-spin"></i>}
      </button>
    </div>
  );
}

export default EditExtendLock;
