import React, { useState } from "react";
import "../Pages/TokenLock/index.css";
import { ethers } from "ethers";
import { useSigner } from "wagmi";
import { lockAbi } from "../Contract/lock";
import { toast } from "react-toastify";

function EditLockTitle({ record, provider, contractAddress ,setTrigger,trigger,setModalTitle }) {
  const { data: signer } = useSigner();
  const currentSigner =
    signer === undefined || signer === null ? provider : signer;

  const [lockTitle, setLockTitle] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = async () => {
    const contract = new ethers.Contract(
      contractAddress,
      lockAbi,
      currentSigner
    );

    const saveToast = toast.loading("Transaction in progress...", {
        position: "top-center",
      });
    setSaveLoading(true);
    const lockId = record?.lockRecord?.id
    try {
      const tx = await contract.editLockDescription(lockId,lockTitle);
      await tx.wait();
      setTrigger(trigger+1)
      setModalTitle(false)
      toast.update(saveToast, {
        render: "Transaction Successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });

    } catch (error) {
        console.log(error);
        toast.update(saveToast, {
            render: error
              ? error.reason !== undefined
                ? error.reason
                : error.message !== undefined
                ? error.message
                : "Something went wrong"
              : "Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeButton: true,
          });

      
    }
    setSaveLoading(false);
  };

  return (
    <div className="transferModal">
      <span>New Title</span>
      <input 
      type="text" 
      placeholder="Ex: Michel's Lock" 
      value={lockTitle}
      onChange={(e)=>setLockTitle(e.target.value)}
      />
      <button
      disabled={saveLoading || lockTitle === ""}
      style={{
        cursor: saveLoading || lockTitle === "" ? "not-allowed" : "pointer",
        opacity: saveLoading || lockTitle === "" ? 0.5 : 1,
        gap: saveLoading ? "6px" : "0",
      }}
      onClick={()=>handleSave()}

      >Save{saveLoading && <i className="fas fa-spinner fa-spin"></i>}</button>
    </div>
  );
}

export default EditLockTitle;
