import React, { useState } from "react";
import "../Pages/TokenLock/index.css";
import { ethers } from "ethers";
import { useSigner } from "wagmi";
import { lockAbi } from "../Contract/lock";
import { toast } from "react-toastify";

function TransferLockOwnership({
  record,
  provider,
  contractAddress,
  setTrigger,
  trigger,
  setModalTransfer,
}) {
  const { data: signer } = useSigner();
  const currentSigner =
    signer === undefined || signer === null ? provider : signer;

  const [transferAddress, setTransferAddress] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = async () => {
    const contract = new ethers.Contract(
      contractAddress,
      lockAbi,
      currentSigner
    );

    const saveToast = toast.loading("Transaction in progress...", {
      position: "top-center",
    });
    setSaveLoading(true);
    const lockId = record?.lockRecord?.id;
    try {
      const tx = await contract.transferLockOwnership(lockId, transferAddress);
      await tx.wait();
      setTrigger(trigger + 1);
      setModalTransfer(false);
      toast.update(saveToast, {
        render: "Transaction Successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    } catch (error) {
      console.log(error);
      toast.update(saveToast, {
        render: error
          ? error.reason !== undefined
            ? error.reason
            : error.message !== undefined
            ? error.message
            : "Something went wrong"
          : "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    }
    setSaveLoading(false);
  };

  return (
    <div className="transferModal">
      <span>New Owner Address</span>
      <input
        type="text"
        placeholder="Ex: 0x1FAf588150749cAa9E87e1Cd45d29d606c1B8596"
        value={transferAddress}
        onChange={(e) => setTransferAddress(e.target.value)}
      />
      <button
        disabled={saveLoading || transferAddress === ""}
        style={{
          cursor: saveLoading || transferAddress === "" ? "not-allowed" : "pointer",
          opacity: saveLoading || transferAddress === "" ? 0.5 : 1,
          gap: saveLoading ? "6px" : "0",
        }}
        onClick={() => handleSave()}
      >
        Transfer Ownership
        {saveLoading && <i className="fas fa-spinner fa-spin"></i>}
      </button>
    </div>
  );
}

export default TransferLockOwnership;
