import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./reset.css"
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Buffer } from "buffer";
import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import {
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import AlveyChainLogo from "./Assets/alveyChainLogo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import '@rainbow-me/rainbowkit/styles.css';


window.Buffer = Buffer;
const alvey = {
  id: 3797,
  name: "Alvey",
  network: "Alvey Chain",
  iconUrl:AlveyChainLogo,
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Alvey",
    symbol: "ALV",
  },
  rpcUrls: {
    default: "https://elves-core2.alvey.io",
  },
  blockExplorers: {
    default: {
      name: "Alvey",
      url: "https://alveyscan.com/",
    },
    mainn: {
      name: "Alvey",
      url: "https://alveyscan.com/",
    },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [alvey],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      walletConnectWallet({ chains }),
      coinbaseWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig client={wagmiClient}>
  <RainbowKitProvider modalSize="compact" chains={chains}>
      <App />
  </RainbowKitProvider>
</WagmiConfig>
);

reportWebVitals();
